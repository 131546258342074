import React, { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import classNames from "classnames";
import Cookies from "js-cookie";
import { Context } from "../../../../utils/context";
import { useForm } from "react-hook-form";
import ScrollToTop from "../../../ScrollTotop/ScrollTotop";

const Stepnine = (props) => {
  const [show, setShow] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(
    "https://www.clickdimensions.com/links/TestPDFfile.pdf"
  ); // Replace with the actual path to the admin-uploaded PDF
  const [uploadedPdfUrl, setUploadedPdfUrl] = useState(null);
  const [adminPdfName, setAdminPdfName] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file type is PDF
      if (file.type === "application/pdf") {
        const fileUrl = URL.createObjectURL(file);
        setUploadedPdfUrl(fileUrl);
      } else {
        // Notify the user that only PDF files are allowed
        alert("Please upload a PDF file.");
      }
    }
  };

  useEffect(() => {
    const fileName = pdfUrl.split("/").pop();
    setAdminPdfName(fileName);
  }, [pdfUrl]);

  const { getData, IMG_URL, postData } = useContext(Context);
  const [user_id, setUserId] = useState("");

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const imageFile = watch("document");

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (data?.id) {
      formData.append("id", data?.id);
    }
    formData.append("user_id", user_id);
    formData.append("document", data?.document[0]);
    formData.append(
      "transaction_id",
      data?.transaction_id ? data?.transaction_id : null
    );
    formData.append("payment_type", data?.payment_type);
    formData.append("payment_rs", data?.payment_rs);

    try {
      const res = await postData("/seller/s-onboarding-document", formData);
      if (res?.success) {
        props.nextStep();
      } else {
        if (res?.message?.pan_no) {
          setError("document", { message: res?.message?.document });
        }
      }
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("An error occurred while submitting data:", error);
    }
  };

  const getEditData = async () => {
    const res = await getData(`/seller/s-onboarding-document/${user_id}`);
    if (res?.success) {
      reset(res?.data);
    }
  };

  useEffect(() => {
    getEditData();
  }, [user_id]);

  useEffect(() => {
    setUserId(Cookies.get("user_id"));
  }, []);

  return (
    <>
      <ScrollToTop />
      <section className="personal-details-form">
        <div className="form-container">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="Stepnine">
              <div className="row itemDetails">
                <div className="col-md-6">
                  <div className="field-bottom">
                    <div className="">
                      <Form.Label className="required">
                        Enter Transaction Id*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="transaction_id"
                        placeholder="Transaction Id"
                        {...register("transaction_id", {})}
                        className={classNames("", {
                          "is-invalid": errors?.transaction_id,
                        })}
                      />

                      {errors.transaction_id && (
                        <span className="text-danger">
                          {errors.transaction_id.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-bottom">
                    <div>
                      <Form.Label className="required">
                        Payment Type *
                      </Form.Label>
                      <Form.Select
                        name="payment_type"
                        {...register("payment_type", {
                          required: "Payment Type is required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.payment_type,
                        })}
                      >
                        <option value="">Select Payment Rupees</option>
                        <option value="online">Online</option>
                        <option value="cash">Cash</option>
                      </Form.Select>
                      {errors.payment_rs && (
                        <span className="text-danger">
                          {errors.payment_rs.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-bottom">
                    <div>
                      <Form.Label className="required">
                        Payment Type *
                      </Form.Label>
                      <Form.Select
                        name="payment_rs"
                        {...register("payment_rs", {
                          required: "Payment Rupees is required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.payment_rs,
                        })}
                      >
                        <option value="">Select Payment Rupees</option>
                        <option value="99">99</option>
                        <option value="999">999</option>
                      </Form.Select>
                      {errors.payment_rs && (
                        <span className="text-danger">
                          {errors.payment_rs.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-field mb-3">
                    <Form.Group controlId="Last_Name">
                      <Form.Label className="required">
                        Upload Payment Image*
                      </Form.Label>
                      <div>
                        <div>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.document,
                            })}
                            type="file"
                            {...register("document", {
                              validate: async (value) => {
                                if (typeof value !== "string") {
                                  const fileTypes = ["jpg", "png", "jpeg"];
                                  const fileType = value[0]?.name?.split(
                                    "."
                                  )[1];

                                  if (!fileTypes.includes(fileType)) {
                                    return `please upload a valid file format. (${fileTypes})`;
                                  }
                                }
                              },
                            })}
                            accept=".jpg, .jpeg, .png"
                            multiple={false}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="main-form-section mt-3">
                  <Form.Label>Document Preview </Form.Label>

                  {typeof getValues("document") == "string" ? (
                    <div
                      className="image-preview-container mt-3"
                      style={{ marginLeft: "110px" }}
                    >
                      <img
                        src={IMG_URL + getValues("document")}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </div>
                  ) : (
                    imageFile &&
                    imageFile?.length > 0 && (
                      <div
                        className="image-preview-container mt-3"
                        style={{ marginLeft: "110px" }}
                      >
                        <img
                          // src={URL.createObjectURL(getValues("image")[0])}
                          src={URL?.createObjectURL(imageFile[0])}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="text-end apply_now_btn">
              <div className="registerContinueMain">
                <Button onClick={props.prevStep} className="back-btn me-3">
                  Back
                </Button>
                <Button className="tabs-btn" type="submit">
                  Next
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </section>
    </>
  );
};

export default Stepnine;
