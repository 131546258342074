import React, { useState } from "react";
import "./CallSupportModal.css";

import PhoneInput from "react-phone-input-2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
// import { supportQueryPost } from "../../../utils/apis/support";

import { RegxExpression } from "../../../utils/common";
import { Errors, Placeholders } from "../../../utils/errors";
import SuccessModalDynamic from "../SuccessModalDynamic/SuccessModalDynamic";
import ErrorModalDynamic from "../ErrorModalDynamic/ErrorModal";
import { ComlaintQuery } from "../../../utils/apis/Complaint";

const CallSupportModal = (props) => {
  const [error, setErrors] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (d) => {
    let data = {};

    data.query = d?.query;
    data.name = d?.name;
    data.email = d?.email;
    data.contact_no = d?.contact_no;
    data.order_detail_id = props?.order_detail_id;

  

    const res = await ComlaintQuery(data);

    if (res?.success) {
      reset();
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
        props.onHide();
        props?.getOrders();
        props?.getAllComplaintQuery();
      }, 3000);
    } else {
      setErrors(res?.message);
      setErrorModal(true);
      setTimeout(() => {
        setErrorModal(false);
        setErrors("");
      }, 3000);
    }
  };

  console.log(errors);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="CallSupportModal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Raise a Query
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col lg={12} md={12} sm={12}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group mb-3">
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter Name"
                      {...register("name", {
                        required: "Name required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Enter Email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: RegxExpression.email,
                          message: "Invalid email address",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.email,
                      })}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <Form.Control
                      type="text"
                      name="contact_no"
                      placeholder="Enter Contact"
                      inputMode="numeric"
                      maxLength="10"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      {...register("contact_no", {
                        required: "Contact required",
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: "Contact number must be 10 digits",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                      })}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Enter your query"
                    >
                      <Form.Control
                        as="textarea"
                        type="text"
                        name="query"
                        placeholder="Leave a query here"
                        {...register("query", {
                          required: "Query required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.query,
                        })}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="form-group mb-3">
                    <div className="text-end">
                      <button className="submitbtn btn">Submit</button>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </Tab.Container>
          <ErrorModalDynamic
            show={errorModal}
            onHide={() => setErrorModal(false)}
            text={error}
            heading={"Error!"}
          />
          <SuccessModalDynamic
            show={successModal}
            onHide={() => setSuccessModal(false)}
            text={"Query Submitted Successfully"}
          />
        </Modal.Body>

        {/* <Button onClick={props.onHide}>Close</Button> */}
      </Modal>
    </>
  );
};

export default CallSupportModal;
