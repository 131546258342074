import React, { useState, useEffect, useCallback, useContext } from "react";
import "./FirstVendorDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import {
  getProductSubVariant,
  getProductVariant,
} from "../../../../utils/apis/Product/Product";
import {
  faCartShopping,
  faHeart as solidHeart,
} from "@fortawesome/free-solid-svg-icons";
import { getCartCheck } from "../../../../utils/apis/addToCart/addToCart";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";

import {
  AddToCart,
  CartQuantity,
} from "../../../../utils/apis/addToCart/addToCart";
import { getAppSetup } from "../../../../utils/apis/common/Common";
const FirstVendorDetails = ({
  product,
  cartStatus,
  cartData,
  getProduct,
  setCartStatus,
  setSelected,
  setsubSelected,
  setCartData,
}) => {
  const {
    variant,
    setVariant,
    setsubVariant,
    triggerCartUpdate,
    appSetup,
    setAppSetup,
    cartCount,
  } = useContext(Context);


  const [number, setNumber] = useState(1);
  const [activeSubVariantId, setActiveSubVariantId] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const [showHeart, setShowHeart] = useState(false); // State for heart icon
  const [prdId, setPrdId] = useState("");
  const [variants, setVariants] = useState([]);
  const [subVariants, setSubVariants] = useState([]);

  const [selectedVariant, setSelectedVariant] = useState(null);


  const [selectedSubVariant, setSelectedSubVariant] = useState(null);


  const [dataCart, setDataCart] = useState({});

  const handleMinusClick = () => {
    if (number > 1) {
      setNumber(number - 1);
    }
  };

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const handlePlusClick = () => {
    setNumber(number + 1);
  };

  const handleHeartClick = () => {
    setShowHeart(!showHeart); // Toggle the heart icon state
    setShow(false); // Hide the section with blue background and buttons
  };
  const [show, setShow] = useState(false);

  const addCart = async (data) => {
    const res = await AddToCart(data);
    await getProduct();
    // const data = { product_id: product?.id, variant_id: selectedVariant?.id };
    const response = await getCartCheck(data);


    if (response?.success) {
      setCartStatus(true);
      setCartData(response?.cartItem);
      triggerCartUpdate();
    } else {
      setCartStatus(false);
      setCartData(null);
      triggerCartUpdate();
    }
  };

  const getCartQuantity = async (id, type, quantity) => {
    await CartQuantity(
      id,
      type,
      selectedVariant?.id ? selectedVariant?.id : selectedSubVariant?.id
    );
    await getProduct();
    triggerCartUpdate();
    if (quantity == 1 && type === "minus") {
      setCartStatus(false);
      triggerCartUpdate();
    }
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const appSetupGet = async () => {
    const res = await getAppSetup();
    if (res?.success) {
      setAppSetup(res?.data);
    }
  };

  useEffect(() => {
    appSetupGet();
  }, []);

  useEffect(() => {
    setPrdId(product?.id);
    getProducts(prdId);
    getSubProducts(prdId);
  }, [product]);

  const getProducts = async () => {
    const res = await getProductVariant(product?.id);
    if (res?.success) {
      setVariants(res?.data);
      if (!selectedVariant) {
        setSelectedVariant(res?.data[0]);
        setSelected(res?.data[0]);
        setVariant(res?.data[0]);
      }
    }
  };

  const getSubProducts = async () => {
    const res = await getProductSubVariant(product?.id);
    if (res?.success) {
      setSubVariants(res?.data);
      if (!selectedSubVariant) {
        setSelectedSubVariant(res?.data[0]?.product_multiple_variants?.[0]);
        setsubSelected(res?.data[0]);
        setsubVariant(res?.data);
        setActiveSubVariantId(res?.data[0]?.product_multiple_variants?.[0]?.id);
      }
    }
  };

  const handleVariantChange = async (variant) => {
    setSelectedVariant(variant);
    setSelected(variant);
    setVariant(variant);
    setSelectedSubVariant(0);
  };

  const handleSubVariantChange = async (subvariant) => {
    setSelectedSubVariant(subvariant);
    setsubSelected(subvariant);
    setsubVariant(subvariant);
    setActiveSubVariantId(subvariant.id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {
          product_id: product?.id,
          variant_id: selectedVariant?.id,
          m_variant_id: selectedSubVariant?.id,
        };
        const response = await getCartCheck(data);
        triggerCartUpdate();
        if (response?.success) {
          setCartStatus(true);
          // setCartData(response?.cartItem);
        } else {
          setCartStatus(false);
          // setCartData(null);
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    };
    fetchData();
  }, [selectedVariant, selectedSubVariant]);

  useEffect(() => {
    triggerCartUpdate();
  }, [cartStatus]);

  const [activeVariantId, setActiveVariantId] = useState(null);

  useEffect(() => {
    if (subVariants?.length > 0) {
      setActiveVariantId(subVariants[0]?.id);
    }
  }, [subVariants]);

  return (
    <section className="first-vendor-details">
      <div className="title-details mt-5">
        <h2>
          {product?.name}
          {/* <Link to='/view-all-scanmart'><span className="red-seller ms-1">View Seller Product</span></Link> */}
        </h2>
        <p className="mrp-class">
          MRP. ₹.{" "}
          {selectedVariant?.s_price
            ? selectedVariant?.s_price
            : selectedSubVariant?.product_multiple_variants?.[0]?.s_price
            ? selectedSubVariant?.product_multiple_variants?.[0]?.s_price
            : selectedSubVariant?.s_price}
          {/* <span>(Incl. of all taxes)</span> */}
        </p>
        <p className="return-mrp">
          mrp ₹.{" "}
          {selectedVariant?.mrp
            ? selectedVariant?.mrp
            : selectedSubVariant?.product_multiple_variants?.[0]?.mrp
            ? selectedSubVariant?.product_multiple_variants?.[0]?.mrp
            : selectedSubVariant?.mrp}
        </p>{" "}
        {/* <p className="in-stock">In Stock</p> */}
        <p className="in-stock">
          {Number(selectedVariant?.unit) > 1
            ? "In Stock"
            : Number(selectedSubVariant?.unit) > 1
            ? "In Stock"
            : "Out of Stock"}
        </p>
      </div>

      <div className="variationdivvvvsize mb-3">
        <h6>Variations</h6>

        <Nav className="horizontal-nav  mb-3">
          {variants.map((variant) => (
            <Nav.Item key={variant.id}>
              <Nav.Link
                eventKey={`v_name${variant.id}`}
                className={selectedVariant?.id === variant.id ? "active" : ""}
                onClick={() => handleVariantChange(variant)}
              >
                {variant?.v_name}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>

        <Tab.Container
          className="mt-4"
          id="left-tabs-example"
          defaultActiveKey="0"
        >
          <Nav
            variant="pills"
            className="multivar-divv"
            activeKey={`variant_sub_name${activeVariantId}`}
          >
            {subVariants?.map((subvariant, index) => (
              <Nav.Item
                key={subvariant?.product_multiple_variants
                  ?.map((value) => value.id)
                  .join(",")}
              >
                <Nav.Link
                  eventKey={index}
                  className={
                    activeVariantId === subvariant?.id ? "active-link" : ""
                  }
                >
                  {subvariant?.varient_name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <Tab.Content className="tabcontttttt mt-2">
            {subVariants?.map((subvariant, index) => (
              <Tab.Pane
                key={subvariant?.product_multiple_variants
                  ?.map((value) => value.id)
                  .join(",")}
                eventKey={index}
              >
                <div className="sub-variant-container">
                  {subvariant.product_multiple_variants.map(
                    (subInnervariant) => (
                      <p
                        key={subInnervariant.id}
                        className={`sub-variant-item ${
                          activeSubVariantId === subInnervariant.id
                            ? "active"
                            : ""
                        }`}
                        onClick={() => handleSubVariantChange(subInnervariant)}
                      >
                        {subInnervariant.varient_sub_name}
                      </p>
                    )
                  )}
                </div>
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </div>
      <div className="button-effect ">
        {cartStatus ? (
          <div className="blue-bg d-flex  me-3">
            <div className="circle-holder1">
              <button
                className="minus-btn me-md-5 me-0"
                type="button"
                onClick={() =>
                  getCartQuantity(cartData?.id, "minus", cartData?.quantity)
                }
              >
                <FontAwesomeIcon
                  className="minus-icon"
                  icon="fa-solid fa-minus"
                />
              </button>
            </div>
            <p className="number-text ">{cartData?.quantity}</p>
            <div className="circle-holder1">
              <button
                className="minus-btn"
                type="button"
                onClick={() =>
                  getCartQuantity(cartData?.id, "add", cartData?.quantity)
                }
              >
                <FontAwesomeIcon icon="plus" />
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex">
            <Common_Button
              onClick={() =>
                addCart({
                  product_id: product?.id,
                  variant_id: selectedVariant?.id,
                  m_variant_id: selectedSubVariant?.id,
                })
              }
              className="add-to-cart-btn me-3"
              icon={faCartShopping} // Pass the icon as prop
              text="Add to Cart"
            />

            <Link to="/product-cart">
              <Common_Button
                className="add-to-cart-btn"
                text=" Buy Now"
                icon="fa-solid fa-cart-shopping"
                onClick={() =>
                  addCart({
                    product_id: product?.id,
                    variant_id: selectedVariant?.id,
                    m_variant_id: selectedSubVariant?.id,
                  })
                }
              />
            </Link>
          </div>
        )}
        <Link to={"/product-cart"}>
          <Common_Button
            className="add-to-cart-btn ms-3 me-3"
            icon={faCartShopping} // Pass the icon as prop
            text="Go to Cart"
          />
        </Link>
      </div>

      {/* After Onlclick */}
      <div className="description-class mt-4 mb-4">
        <h2 className="description-class-title">Description</h2>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills" className="flex-row">
            {product?.product_detail?.about && (
              <Nav.Item>
                <Nav.Link eventKey="first">About the Product</Nav.Link>
              </Nav.Item>
            )}
            {product?.product_detail?.benifits && (
              <Nav.Item>
                <Nav.Link eventKey="second">Benefits</Nav.Link>
              </Nav.Item>
            )}
            {product?.product_detail?.storage && (
              <Nav.Item>
                <Nav.Link eventKey="third">Product Description</Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link eventKey="four">General Information</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="mt-3">
            <Tab.Pane eventKey="first">
              <p className="text-holder-vendor-page">
                {htmlToReactParser.parse(product?.product_detail?.about)}
              </p>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <p className="text-holder-vendor-page">
                {htmlToReactParser.parse(product?.product_detail?.benifits)}
              </p>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <p className="text-holder-vendor-page">
                {htmlToReactParser.parse(product?.product_detail?.storage)}
              </p>
            </Tab.Pane>
            <Tab.Pane eventKey="four">
              <Row>
                <Col xl={3}>
                  <p className="brod-title">Brand:</p>
                </Col>
                <Col xl={9}>
                  <p className="brod-subtitle">
                    {product?.product_detail?.brand?.name}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xl={3}>
                  <p className="brod-title">Manufaturer:</p>
                </Col>
                <Col xl={9}>
                  <p className="brod-subtitle">
                    {product?.product_detail?.manufacturer}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xl={3}>
                  <p className="brod-title">Vendor:</p>
                </Col>
                <Col xl={9}>
                  <p className="brod-subtitle">
                    {product?.product_detail?.vender}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xl={3}>
                  <p className="brod-title">Sold by:</p>
                </Col>
                <Col xl={9}>
                  <p className="brod-subtitle">{product?.user?.first_name}</p>
                </Col>
              </Row>
              <div className="orange-bg-details">
                <Row>
                  <Col xl={6}>
                    <p className="brod-title-2">Seller Email:</p>
                  </Col>
                  <Col xl={6}>
                    <p className="brod-subtitle-2">{product?.user?.email}</p>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6}>
                    <p className="brod-title-2">
                      BoomerBucks Customer Care Phone:{" "}
                    </p>
                  </Col>
                  <Col xl={6}>
                    <p className="brod-subtitle-2">+91 5483580687</p>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6}>
                    <p className="brod-title-2">Country of Origin: </p>
                  </Col>
                  <Col xl={6}>
                    <p className="brod-subtitle-2">India</p>
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </section>
  );
};

export default FirstVendorDetails;
