import React, { useEffect } from "react";
import SupermarketCart from "../../Animation/supermarket-cart/SupermarketCart";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
const SupermarketModal = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.onHide();
    }, 3000);
  }, [props.show]);
  return (
    <>
      <div className="Successfull_Modal_sec">
        <Modal
          className="Successfull_Modal"
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="lottie-holder">
              {" "}
              <SupermarketCart />
            </div>

            <p>Your Products Added Into Cart Successfully</p>
            <div className="text-center">
              <Link to="/product-cart">
                <h4>View Cart</h4>
              </Link>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default SupermarketModal;
