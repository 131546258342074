import React, { useState, useEffect, useContext } from "react";
import "./Order_Cancle.css";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Successfull_Modal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
import Cancle_Reason_Modal from "../../../Common_modal/Cancle_Reason_Modal/Cancle_Reason_Modal";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import {
  singleOrderGet,
  OrderCancel,
} from "../../../../utils/apis/myAccount/myAccount";
import { Context } from "../../../../utils/context";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Order_Cancle({}) {
  const { IMG_URL, getuserData } = useContext(Context);
  const location = useLocation();
  const { show, orderId } = location.state || {};


  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [modalCanleReasonShow, setModalCanleReasonShow] = useState(false);

  const [reason, setReason] = useState("");

  const [remark, setRemark] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    setErrorMessage("");

    if (!reason) {
      setErrorMessage(" Please select a reason for cancellation.");
      return;
    }

    const data = { order_id: orderId, cancel_reason_id: reason };

    if (remark) {
      data.remark = remark;
    }

    const res = await OrderCancel(orderId, data);
    if (res?.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        navigate("/my-account");
        getuserData();
      }, 3000);
    } else {
      setErrorMessage(
        res?.message?.errors?.reason?.msg || "Submission failed. Try again."
      );
    }
  };

  const [order, setOrder] = useState([]);
  const getOrder = async () => {
    const res = await singleOrderGet(orderId);
    if (res?.success) {
      setOrder(res?.data);
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <>
      <div className="Order_Cancle my-account">
        <div className="container">
          <div className="product-section-div">
            <div className="product-div">
              <div className="row">
                {order?.orders_details?.map((items) => (
                  <div
                    className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-4"
                    key={items.product_id}
                  >
                    {items?.product?.product_images?.map((image) => (
                      <div className="product" key={image.id}>
                        <img
                          className="img-set"
                          src={IMG_URL + image?.image}
                          alt=""
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div className="text-holder">
              <h4>Order ID :- </h4>
              <p>{order?.id}</p>
              <h4>
                Product Name:-
                <div>{order?.product?.name}</div>
              </h4>

              <h4>Amount - {order?.price}</h4>
            </div>
          </div>

          <div
            className="Cancellation"
            onClick={() => setModalCanleReasonShow(true)}
          >
            <p>
              Select a reason for cancellation{" "}
              <FontAwesomeIcon
                className="ms-2"
                icon="fa-solid fa-angle-right"
              />
              <span style={{ color: "red" }}>{errorMessage}</span>
            </p>
          </div>

          <div className="Cancellation  Cancellation-reason">
            <p>
              Could you tell us a reason for cancellation?{" "}
              <span>(optional)</span>
            </p>
            <Form>
              <Form.Control
                as="textarea"
                role="1"
                placeholder="Type Your Personal Reason"
                onChange={(e) => setRemark(e.target.value)}
              />
            </Form>

            {/* {reason && (
              <p>
                Your Reason:
                <span> {reason}</span>
              </p>
            )} */}

            <div className="btn-holder">
              <Common_Button
                text="Cancel"
                className="cancel-btn"
                onClick={() => navigate("/my-account")}
              />

              <Common_Button
                onClick={handleSubmit}
                text="Submit"
                className="submit-btn"
              />
            </div>
          </div>
        </div>
      </div>

      <Successfull_Modal
        message=" Your order has been canceled successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Cancle_Reason_Modal
        reason={reason}
        setErrorMessage={setErrorMessage}
        setReason={setReason}
        show={modalCanleReasonShow}
        onHide={() => setModalCanleReasonShow(false)}
      />
    </>
  );
}

export default Order_Cancle;
