// import React from "react";
// import "./Cancle_Reason_Modal.css";
// import { Form, Modal } from "react-bootstrap";

// function Cancle_Reason_Modal(props) {
//   const handleReason = async (d) => {
//     await props.setReason(d);
//     props.onHide();
//   };

//   return (
//     <>
//       <div className="Reason_Modal">
//         <Modal
//           className=" Cancel_Reason_Modal"
//           {...props}
//           size="lg"
//           aria-labelledby="contained-modal-title-vcenter"
//           centered
//         >
//           <Modal.Header closeButton>
//             {" "}
//             Select a reason for {props.text}
//           </Modal.Header>
//           <Modal.Body>
//             <div className="select_reason">
//               <Form>
//                 <Form.Check
//                   label="Product price has reduced"
//                   name="name"
//                   type="radio"
//                   value="Product price has reduced"
//                   onClick={() => handleReason("Product price has reduced")}
//                   defaultChecked={props.reason === "Product price has reduced"}
//                 />
//                 <Form.Check
//                   label="Wrong contact number entered"
//                   name="name"
//                   type="radio"
//                   value="Wrong contact number entered"
//                   onClick={() => {
//                     handleReason("Wrong contact number entered");
//                     props.setErrorMessage("");
//                   }}
//                   defaultChecked={
//                     props.reason === "Wrong contact number entered"
//                   }
//                 />
//                 <Form.Check
//                   label="Ordered by mistake"
//                   name="name"
//                   type="radio"
//                   value="Ordered by mistake"
//                   onClick={() => {
//                     handleReason("Ordered by mistake");
//                     props.setErrorMessage("");
//                   }}
//                   defaultChecked={props.reason === "Ordered by mistake"}
//                 />
//                 <Form.Check
//                   label="Incorrect product size/colour/type ordered"
//                   name="name"
//                   type="radio"
//                   value="Incorrect product size/colour/type ordered"
//                   onClick={() => {
//                     handleReason("Incorrect product size/colour/type ordered");
//                     props.setErrorMessage("");
//                   }}
//                   defaultChecked={
//                     props.reason ===
//                     "Incorrect product size/colour/type ordered"
//                   }
//                 />
//                 <Form.Check
//                   label="Purchased product from somewhere else"
//                   name="name"
//                   type="radio"
//                   value="Purchased product from somewhere else"
//                   onClick={() => {
//                     handleReason("Purchased product from somewhere else");
//                     props.setErrorMessage("");
//                   }}
//                   defaultChecked={
//                     props.reason === "Purchased product from somewhere else"
//                   }
//                 />
//                 <Form.Check
//                   label="Wrong address selected"
//                   name="name"
//                   type="radio"
//                   value="Wrong address selected"
//                   onClick={() => {
//                     handleReason("Wrong address selected");
//                     props.setErrorMessage("");
//                   }}
//                   defaultChecked={props.reason === "Wrong address selected"}
//                 />
//                 <Form.Check
//                   label="Product not required anymore"
//                   name="name"
//                   type="radio"
//                   value="Product not required anymore"
//                   onClick={() => {
//                     handleReason("Product not required anymore");
//                     props.setErrorMessage("");
//                   }}
//                   defaultChecked={
//                     props.reason === "Product not required anymore"
//                   }
//                 />
//                 <Form.Check
//                   label="Incorrect payment method selected"
//                   name="name"
//                   type="radio"
//                   value="Incorrect payment method selected"
//                   onClick={() => {
//                     handleReason("Incorrect payment method selected");
//                     props.setErrorMessage("");
//                   }}
//                   defaultChecked={
//                     props.reason === "Incorrect payment method selected"
//                   }
//                 />
//                 <Form.Check
//                   label="Expected delivery time is too long"
//                   name="name"
//                   type="radio"
//                   value="Expected delivery time is too long"
//                   onClick={() => {
//                     handleReason("Expected delivery time is too long");
//                     props.setErrorMessage("");
//                   }}
//                   defaultChecked={
//                     props.reason === "Expected delivery time is too long"
//                   }
//                 />
//                 {/* <Form.Check label="Other" name="name" type="radio"  value="Ordered by mistake"
//                 onClick={() => handleReason("Ordered by mistake")}/> */}
//               </Form>
//             </div>
//           </Modal.Body>
//         </Modal>
//       </div>
//     </>
//   );
// }

// export default Cancle_Reason_Modal;

import React, { useEffect, useState } from "react";
import "./Cancle_Reason_Modal.css";
import { Form, Modal } from "react-bootstrap";
import { OrderCancelReasons } from "../../../utils/apis/master/Master";

function Cancle_Reason_Modal(props) {
  const [cancelReason, setCancelReasons] = useState([]);

  const getCancelreasons = async () => {
    const res = await OrderCancelReasons();
    if (res?.success) {
      setCancelReasons(res.data);
    }
  };

  useEffect(() => {
    getCancelreasons();
  }, []);

  const handleReason = async (d, name) => {
    await props.setReason(d);
    props.onHide();
  };

  return (
    <>
      <div className="Reason_Modal">
        <Modal
          className="Cancel_Reason_Modal"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            Select a reason for Cancellation
          </Modal.Header>
          <Modal.Body>
            <div className="select_reason">
              <Form>
                {cancelReason.map((data) => (
                  <Form.Check
                    key={data?.id} // Added unique key prop
                    label={data?.name} // Use dynamic label
                    name="cancel_reason"
                    type="radio"
                    value={data?.id}
                    onClick={() => handleReason(data?.id, data?.name)}
                    defaultChecked={props.reason === data?.name}
                  />
                ))}
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Cancle_Reason_Modal;
