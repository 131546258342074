import React, { useContext, useState, useEffect } from "react";
import "./ProductPage.css";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHeart, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heart_Component from "../../../Common-Component/Heart_Component/Heart_Component";

import { ShimmerThumbnail } from "react-shimmer-effects";
import { Context } from "../../../../utils/context";
library.add(fas);

const ProductPage = ({ product, selectedVariant, selectedsubVariant }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSubImage, setSelectedSubImage] = useState(null);

  useEffect(() => {
    if (selectedVariant?.product_images) {
      setSelectedImage(selectedVariant?.product_images?.[0]?.image);
    }
  }, [selectedVariant]);

  useEffect(() => {
    if (selectedsubVariant?.product_multi_varient_images) {
      setSelectedImage(
        selectedsubVariant?.product_multi_varient_images?.[0]?.image
      );
    }
  }, [selectedsubVariant]);

  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleImageClick = (imageSrc) => {
  

    setSelectedImage(imageSrc);
  };
  const handleSubImageClick = (subimageSrc) => {
    setSelectedSubImage(subimageSrc);
    setSelectedImage("");
  };

  useEffect(() => {
    {
      selectedVariant?.product_images &&
        !selectedImage &&
        setSelectedImage(selectedVariant?.product_images?.[0]?.image);
    }
  }, [selectedVariant, selectedImage]);

  useEffect(() => {
    {
      selectedsubVariant?.product_multiple_variants?.[0]
        ?.product_multi_varient_images &&
        !selectedImage &&
        setSelectedSubImage(
          selectedsubVariant?.product_multiple_variants?.[0]
            ?.product_multi_varient_images?.[0]?.image
        );
    }
  }, [selectedsubVariant, selectedSubImage]);

  const { IMG_URL, shimmerLoader } = useContext(Context);

  return (
    <section className="cctv-innerpage">
      <div className="container">
        <div className="row camera-sec">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 ">
            {shimmerLoader ? (
              <ShimmerThumbnail height={250} rounded />
            ) : (
              <div className="card-sec">
                <Heart_Component
                  product={product}
                  variant={selectedVariant}
                  m_varient={selectedsubVariant}
                  // variant={
                  //   selectedVariant ? selectedVariant : selectedsubVariant[0]
                  // }
                />
                <div className="product-detail text-center">
                  {selectedImage ? (
                    <img
                      className="product-img"
                      src={IMG_URL + selectedImage}
                      alt="Selected"
                    />
                  ) : (
                    <img
                      className="product-img"
                      src={IMG_URL + selectedSubImage}
                      alt="Selected"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="camera-slider">
              <Swiper
                slidesPerView={"auto"}
                modules={[Autoplay]}
                spaceBetween={20}
                breakpoints={{
                  0: { slidesPerView: 3, spaceBetween: 10 },
                  380: { slidesPerView: 3 },
                  485: { slidesPerView: 3 },
                  575: { slidesPerView: 3 },
                  768: { slidesPerView: 5 },
                  992: { slidesPerView: 3 },
                  1024: { slidesPerView: 3 },
                  1200: { slidesPerView: 4 },
                  1440: { slidesPerView: 4 },
                  2000: { slidesPerView: 4 },
                }}
                navigation
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                  loop: true,
                }}
                className="mySwiper"
              >
                {selectedVariant?.product_images?.map((imageSrc, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="camera-imgs"
                      onClick={() => handleImageClick(imageSrc?.image)}
                    >
                      <img
                        src={IMG_URL + imageSrc?.image}
                        className="inner-img"
                      />
                    </div>
                  </SwiperSlide>
                ))}
                {/* {selectedsubVariant?.product_multiple_variants?.map(
                  (image, imgIndex) =>
                    image?.product_multi_varient_images?.map((value, index) => {
                      <SwiperSlide key={`${imgIndex}`}>
                        <div
                          className="camera-imgs"
                          onClick={() => handleSubImageClick(value?.image)}
                        >
                          <img
                            src={IMG_URL + value?.image}
                            className="inner-img"
                            alt=""
                          />
                        </div>
                      </SwiperSlide>;
                    })
                )} */}

                {selectedsubVariant?.product_multi_varient_images?.map(
                  (image, imgIndex) => (
                    <SwiperSlide key={`${imgIndex}`}>
                      <div
                        className="camera-imgs"
                        onClick={() => handleSubImageClick(image?.image)}
                      >
                        <img
                          src={IMG_URL + image?.image}
                          className="inner-img"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                  )
                  // ))
                )}
              </Swiper>

              {selectedVariant?.product_images > 4 && (
                <>
                  <div
                    className="main-slider-cctv"
                    onClick={() => handleMainSliderClick("prev")}
                  >
                    <div className="arrow-back" />
                  </div>
                  <div
                    className="main-slider-cctv"
                    onClick={() => handleMainSliderClick("next")}
                  >
                    <div className="arrow-next" />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductPage;
