import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import { Context } from "../../../../utils/context";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../ScrollTotop/ScrollTotop";

function Stepthree(props) {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    watch,
  } = useForm();


  const { getData, IMG_URL, Select2Data, postData } = useContext(Context);
  const [user_id, setUserId] = useState("");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "s_commission_details",
  });

  // Watch specific fields
  const sCommissionDetails = watch("s_commission_details", []);
  const companyNames = sCommissionDetails.map((data) => data?.company_name);
  const takePercent = sCommissionDetails.map((data) => data?.take_percent);
  const givePercent = sCommissionDetails.map((data) => data?.give_percent);

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const formData = new FormData();

    // Prepare the array of commission details
    const commissionDetails = data.s_commission_details.map((mainVariant) => ({
      // id: data?.id ? data?.id : null,
      user_id: user_id,
      company_name: mainVariant?.company_name,
      take_percent: mainVariant?.take_percent,
      give_percent: mainVariant?.give_percent,
    }));

    // Append the entire array to the FormData
    formData.append("s_commission_details", JSON.stringify(commissionDetails));

    try {
      const res = await postData("/seller/s-commission_details", formData);
      if (res?.success) {
        props.nextStep();
        // navigate("/"); // Uncomment this if you want to navigate after successful submission
      } else {
        console.error("Failed to submit data");
      }
    } catch (error) {
      console.error("An error occurred while submitting data:", error);
    }
  };

  const getEditData = async () => {
    const res = await getData(
      `/seller/s-commission_details/?user_id=${user_id}`
    );
    if (res?.success) {
      reset(res?.data);
    }
  };

  useEffect(() => {
    if (user_id) {
      getEditData();
    }
  }, [user_id]);

  useEffect(() => {
    setUserId(Cookies.get("user_id"));
  }, []);

  return (
    <>
      <ScrollToTop />
      <section className="personal-details-form userForm">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="details-form-holder">
            <div className="form-container text-center">
              <FontAwesomeIcon
                icon="fa-solid fa-triangle-exclamation"
                className="trianglealertmark"
              />

              {fields.map((field, index) => (
                <div key={field.id} className="col-md-6">
                  {/* Dynamic message for each entry */}
                  <p>
                    I am getting{" "}
                    <strong>{`${
                      sCommissionDetails[index]?.take_percent || "0"
                    }%`}</strong>{" "}
                    from{" "}
                    <strong>
                      {`(${sCommissionDetails[index]?.company_name || "N/A"})`}
                    </strong>{" "}
                    and I can give{" "}
                    <strong>
                      {`${sCommissionDetails[index]?.give_percent || "0"}%`}
                    </strong>{" "}
                    from my profit margin.
                  </p>

                  <div className="field-bottom">
                    <Form.Label className="required">
                      Front Company Name*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name={`s_commission_details[${index}].company_name`}
                      placeholder="Company Name"
                      {...register(
                        `s_commission_details[${index}].company_name`,
                        {
                          required: "Company Name is required",
                        }
                      )}
                      className={classNames("", {
                        "is-invalid":
                          errors?.s_commission_details?.[index]?.company_name,
                        "is-valid":
                          !errors?.s_commission_details?.[index]
                            ?.company_name &&
                          getValues(
                            `s_commission_details[${index}].company_name`
                          ),
                      })}
                    />
                    {errors.s_commission_details?.[index]?.company_name && (
                      <span className="text-danger">
                        {
                          errors.s_commission_details?.[index]?.company_name
                            .message
                        }
                      </span>
                    )}

                    <Form.Label className="required">
                      Incoming Profit*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name={`s_commission_details[${index}].take_percent`}
                      placeholder="Take Percent"
                      {...register(
                        `s_commission_details[${index}].take_percent`,
                        {
                          required: "Incoming Profit is required",
                        }
                      )}
                      className={classNames("", {
                        "is-invalid":
                          errors?.s_commission_details?.[index]?.take_percent,
                        "is-valid":
                          !errors?.s_commission_details?.[index]
                            ?.take_percent &&
                          getValues(
                            `s_commission_details[${index}].take_percent`
                          ),
                      })}
                    />
                    {errors.s_commission_details?.[index]?.take_percent && (
                      <span className="text-danger">
                        {
                          errors.s_commission_details?.[index]?.take_percent
                            .message
                        }
                      </span>
                    )}

                    <Form.Label className="required">
                      Profit I Can Give*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name={`s_commission_details[${index}].give_percent`}
                      placeholder="Give Percent"
                      {...register(
                        `s_commission_details[${index}].give_percent`,
                        {
                          required: "Out Going Profit is required",
                        }
                      )}
                      className={classNames("", {
                        "is-invalid":
                          errors?.s_commission_details?.[index]?.give_percent,
                        "is-valid":
                          !errors?.s_commission_details?.[index]
                            ?.give_percent &&
                          getValues(
                            `s_commission_details[${index}].give_percent`
                          ),
                      })}
                    />
                    {errors.s_commission_details?.[index]?.give_percent && (
                      <span className="text-danger">
                        {
                          errors.s_commission_details?.[index]?.give_percent
                            .message
                        }
                      </span>
                    )}

                    <Button variant="danger" onClick={() => remove(index)}>
                      Remove
                    </Button>
                  </div>
                </div>
              ))}

              <Button variant="primary" onClick={() => append({})}>
                Add Company
              </Button>
            </div>
          </div>
          <div className="col-md-12">
            <div className="text-end apply_now_btn">
              <div className="registerContinueMain">
                <Button onClick={props.prevStep} className="back-btn me-3">
                  Back
                </Button>
                <Button className="tabs-btn" type="submit">
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </>
  );
}

export default Stepthree;
